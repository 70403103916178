.btn-default {
  @include border-radius(0.4em);
  display: inline-block;
  line-height: 52px;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
}
body {
  &.overflow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}
.form-anchor {
  position: relative;
  display: inline-block;
  height: 0px;
  width: 100%;
}
.form {
  & .form-anchor {
    position: absolute;
    left: 0;
    top: -50px;
  }
}
.page-wr {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 100%;
  min-height: 100%;
  align-items: flex-start;
}