.cases {
    width: 100%;
    max-width: 1080px;
    margin: 340px auto 0;
    padding: 90px;
    color: #3b3e42;
    line-height: 1.8em;
    font-size: 18px;
    background: white
}

.cases h1 {
    display: block;
    color: #131314;
    font-family: "Montserrat-Bold", Helvetica, Arial, sans-serif;
    font-size: 54px;
    font-weight: normal;
    line-height: 69px;
    margin-bottom: 24px;
}

.cases h2 {
    display: block;
    color: #131314;
    font-family: "Montserrat-Medium", Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-weight: normal;
    line-height: 1.3em;
    margin-bottom: .5em;
}

.cases h3 {
    display: block;
    font-family: "Montserrat-Medium", Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.3em;
    margin-bottom: .5em;
}

.cases p {
    margin-bottom: 1.6em;
    line-height: 1.6em;
    font-size: 18px;
}

.cases hr {
    margin: 2em 0;
}

.cases li {
    line-height: 1.6em;
    margin-bottom: .3em;
    display: list-item;
    text-align: -webkit-match-parent;
}

.cases ol {
    list-style-type: decimal;
    margin: 0 0 1em 0;
    padding: 0;
}

.cases ul {
    margin: 0 0 2em 0;
    padding: 0;
}

.cases ol > li {
    margin-left: 1em;
}

.cases ul > li {
    position: relative;
    padding-left: 16px;
}

.cases ul > li:before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 0;
    height: 7px;
    width: 7px;
    border: 2px solid #28aff1;
    border-radius: 100%;
}

.cases__main-image {
    position: absolute;
    width: 100%;
    background-size: cover;
    z-index: -1;
    height: 560px;
    background-color: #0074fe;
}