.thanks-wr {
  height: auto;
  width: 100%;
  padding-top: 185px;
  padding-bottom: 220px;
  @include linear-gradient(to bottom, $blue-F0, $white);
  @media #{$M_XS} {
    padding-top: 100px;
    padding-bottom: 120px;
  }
  &__inner {
    max-width: 1080px;
    margin: 0 auto;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 70px;
    border-bottom: 1px solid $blue-CE;
    @media #{$M_SM2X} {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media #{$M_XS} {
      padding-bottom: 45px;
    }
    & .text-wr {
      width: 100%;
      max-width: 650px;
      padding-top: 30px;
      font-family: $font-OpenSans-Regular;
      font-size: 22px;
      @media #{$M_XS} {
        font-size: 18px;
      }
      & h1 {
        font-size: 36px;
        font-family: $font-Montserrat-SemiBold;
        @media #{$M_XS} {
          font-size: 28px;
        }
      }
      & p {
        margin-top: 35px;
        line-height: 32px;
        @media #{$M_XS} {
          line-height: 30px;
          margin-top: 20px;
        }
        & b {
          font-family: $font-OpenSans-Bold;
        }
      }
    }
    & .pic-wr {
      height: 280px;
      width: 280px;
      background-image: url(../img/thank-icon.png);
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      @media #{$M_SMX} {
        display: none;
      }
    }
  }
  & .btn {
    display: inline-block;
    width: auto;
    margin-top: 80px;
    padding: 0 38px;
    @media #{$M_SM2X} {
      margin-left: 30px;
    }
    @media #{$M_XS} {
      margin-top: 50px;
    }
  }
}