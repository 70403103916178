/*Montserrat*/
@font-face {
    font-family: 'Montserrat-Bold';
    src: url('../fonts/Montserrat/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'),
         url('../fonts/Montserrat/Montserrat-Bold.svg#ProximaNova') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat-Medium';
    src: url('../fonts/Montserrat/Montserrat-Medium.eot');
    src: url('../fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype'),
         url('../fonts/Montserrat/Montserrat-Medium.svg#ProximaNova') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat-Regular';
    src: url('../fonts/Montserrat/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'),
         url('../fonts/Montserrat/Montserrat-Regular.svg#ProximaNova') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('../fonts/Montserrat/Montserrat-SemiBold.eot');
    src: url('../fonts/Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype'),
         url('../fonts/Montserrat/Montserrat-SemiBold.svg#ProximaNova') format('svg');
    font-weight: bold;
    font-style: normal;
}

/*Open-sans*/
@font-face {
    font-family: 'OpenSans-Light';
    src: url('../fonts/Open-sans/OpenSans-Light.eot');
    src: url('../fonts/Open-sans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Open-sans/OpenSans-Light.ttf') format('truetype'),
         url('../fonts/Open-sans/OpenSans-Light.svg#ProximaNova') format('svg');
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('../fonts/Open-sans/OpenSans-Bold.eot');
    src: url('../fonts/Open-sans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Open-sans/OpenSans-Bold.ttf') format('truetype'),
         url('../fonts/Open-sans/OpenSans-Bold.svg#ProximaNova') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans-Regular';
    src: url('../fonts/Open-sans/OpenSans-Regular.eot');
    src: url('../fonts/Open-sans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Open-sans/OpenSans-Regular.ttf') format('truetype'),
         url('../fonts/Open-sans/OpenSans-Regular.svg#ProximaNova') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans-SemiBoldItalic';
    src: url('../fonts/Open-sans/OpenSans-SemiBoldItalic.eot');
    src: url('../fonts/Open-sans/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Open-sans/OpenSans-SemiBoldItalic.ttf') format('truetype'),
         url('../fonts/Open-sans/OpenSans-SemiBoldItalic.svg#ProximaNova') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('../fonts/Open-sans/OpenSans-SemiBold.eot');
    src: url('../fonts/Open-sans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Open-sans/OpenSans-SemiBold.ttf') format('truetype'),
         url('../fonts/Open-sans/OpenSans-SemiBold.svg#ProximaNova') format('svg');
    font-weight: bold;
    font-style: normal;
}