// ----------------------------------------------
// Automatic generation, do not write scss
// -------------------------------------------
.icon-svg {
  display: inline-block;
  // fill: currentColor;
}

.icon-svg-Ellipse3 {
  height: 21.03px;
  width: 23px;
}
.icon-svg-schedule-sub {
  height: 15px;
  width: 15px;
}
.icon-svg-star {
  height: 14px;
  width: 15px;
}
.icon-svg-vk {
  height: 34px;
  width: 34px;
}
