.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 28px 30px;
  z-index: 3;
  background: #fff;
  position: absolute;
  @media #{$M_XLG2} {
    max-width: 100%;
    padding: 20px;
  }

  @media #{$M_SMX} {
    max-width: 100%;
  }

  @media #{$M_SM2} {
    z-index: 4;
  }

  &__logo {
    display: block;
    height: 26px;
    width: 187px;
    margin-top: 10px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-image: url(../img/logo.svg);
    flex-shrink: .2;
    margin-right: 200px;
    @media #{$M_XLG} {
      margin-right: 0;
    }
  }

  & .hdr-nav {
    display: flex;
    align-items: center;
    align-content: center;
    font-family: $font-base;
    width: 100%;
    justify-content: flex-end;
    & > nav {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;
      flex: 1;
      max-width: 840px;
      & > .hdr-nav__item{
        margin: 0 4px;
      }
      & > .hdr-nav__item:not(.extra){
        font-family: $font-Montserrat-Medium;
      }
    }
    &.active {
      display: flex;
    }

    @media #{$M_SMX} {
      display: none;
      position: fixed;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      right: 0;
      left: 0;
      top: 105px;
      height: calc(100vh - 105px);
      padding: 10px 0 10px;
      background-color: rgba(0,0,0,0.85);
      z-index: 3;
    }
    &__item {
      line-height: 40px;
      padding: 0;
      font-size: 16px;
      white-space: nowrap;
      color: $gray-99;
      @media #{$M_XLG2} {
        font-size: 14px;
      }
      @media #{$M_SM} {
        width: 100%;
        padding: 5px 18px;
        text-align: center;
        font-size: 21px;
        color: $white;
      }
      &:last-of-type {
        border-right: transparent;
        padding-right: 0;
        &.extra {
          border-right: transparent;
        }
      }

      & a {
        white-space: nowrap;
        font-family: inherit;
        font-size: inherit;
        color: rgb(17, 111, 255);
        &:hover {
          color: rgb(17, 111, 255);
          opacity: 0.8;
        }
      }
      &.extra {
        margin-left: 44px;
        margin-right: 39px;
        font-size: 16px;
        border: none;
        color: transparent;
        display: flex;
        flex-flow: row nowrap;
        @media #{$M_SMX} {
          color: rgba(255,255,255,0.5);
          justify-content: center;
        }
        @media #{$M_XLG2} {
          margin-left: 10px;
          margin-right: 10px;
        }
        @media #{$M_SM} {
          border-left: none;
          margin-left: 0;
          padding-left: 18px;
          padding-top: 30px;
        }
        & a {
          margin-left: 12px;
          display: inline-block;
          opacity: 1;
          color: rgb(17, 111, 255);
          border: 2px solid rgb(17, 111, 255);
          padding: 14px 28px;
          border-radius: 6px;
          line-height: 1;
          font-family: $font-Montserrat-SemiBold;
          @media #{$M_XLG2} {
            margin-left: 4px;
            padding: 12px 20px;
          }
          &.sign-in{
            &:hover{
              background-color: rgb(17, 111, 255);
              text-decoration: none;
              opacity: 0.8;
              color: #fff;
            }
          }
          &.sign-up {
            background-color: rgb(17, 111, 255);
            color: white;
            border-color:  rgb(17, 111, 255);
            &:hover {
              text-decoration: none;
              background-color: rgb(17, 111, 255);
              opacity: 0.8;
              border-color: rgb(17, 111, 255);
            }
          }
          &:first-of-type {
            margin-left: 0;
            margin-right: 0;
            @media #{$M_XLG2} {
              margin-right: 2px;
            }
          }
        }
      }
    }

    &__lang-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      text-align: left;
      color: rgb(17, 111, 255);
      justify-content: center;
      svg {
        color: inherit;
        margin-left: 8px;
        opacity: 1;
        transition: ease transform .3s;
        transform-origin: 50% 45%;
      }
      &:hover {
        svg {
          opacity: 0.8;
          transform: rotate(180deg);
        }

        .dropdown {
          display: block !important
        }
      }
    }
  }

  & .mobile-menu-trigger {
    display: none;
    position: relative;
    height: 21px;
    width: 30px;
    margin-top: 8px;
    &.active {
      & span {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: 0.2s;
        &:first-of-type {
          @include transform-rotate(-45deg);
        }
        &:nth-of-type(2n) { display: none;}
        &:last-of-type {
          @include transform-rotate(45deg);
        }
      }
    }
    &:active {
      opacity: 0.8;
    }
    & span {
      position: absolute;
      display: inline-block;
      height: 3px;
      width: 100%;
      left: 0;
      right: 0;
      background-color: $gray-4B;
      &:first-of-type { top: 0;}
      &:nth-of-type(2n) { top: 9px;}
      &:last-of-type { bottom: 0;}
    }
    @media #{$M_SMX} {
      display: block;
    }
  }

  $backgroundColor: #e5eef3;
  $buttonColor: #ffffff;
  $textColor: #f1ce64;
  $hoverColor: #fff;
  $facebook: #3B5998;
  $vk: #4a76a8;
  $telegram: #2ca5e0;
  $watsapp: #00B645;
  & .help-widget {
    position: fixed;
    right: 60px;
    bottom: 60px;

    .svg-inline {
      overflow: visible;
      width: 1.125em;
      font-size: 1em;
      display: inline-block;
      height: 1em;
      vertical-align: -.125em;
    }

    a {
      color: $textColor;
      svg {
        position: relative;
        top: 1px;
      }
    }

    &__share {
      position: absolute;
      width: 90px;
      left: 50%;
      margin-left: -45px;
      top: 50%;
      margin-top: -40px;
      border-radius: 80px;
      transition: all 0.3s ease-in-out;
      color: white;
      .btn-wrap {
        position: absolute;
        width: 80px;
        height: 80px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        left: 2px;
        bottom: 1px;
        pointer-events: none;
      }

      svg.hlp-btn {
        width: 80px;
        line-height: 80px;
        display: inline-block;
        font-weight: 700;
        text-transform: uppercase;
        position: absolute;
        left: calc(50% - 40px);
        opacity: 1;
        -webkit-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
        pointer-events: none;
        font-size: 28px;
        text-align: center;

      }

      nav {
        font-size: 0;
      }

      a {
        line-height: 80px;
        width: 80px;
        text-align: center;
        display: inline-block;
        background-color: $buttonColor;
        color: $buttonColor;
        overflow: hidden;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        margin: 0 -40px;
        box-shadow: 0px 10px 44px 0 rgba(17, 70, 96, 0.03), 2px 2px 12px 0 rgba(17, 70, 96, 0.03) ;

        &:nth-child(1) {
          border-radius: 40px;
          margin-left: 0;

          &:hover {
            background-color: $vk;
          }
        }

        &:nth-child(2){
          border-radius: 40px;

          &:hover {
            background-color: $facebook;
          }
        }

        &:nth-child(3){
          border-radius: 40px;

          &:hover {
            background-color: $watsapp;
          }
        }

        &:nth-child(4){
          border-radius: 40px;
          margin-right: 0;
          background-color: rgb(255, 193, 42);
          &:hover {
            background-color: $telegram !important;
          }
        }
      }

      &:hover, &.hover {
        width: 1000px;
        margin-left: -356px;


        svg.hlp-btn {
          opacity: 0;
        }

        a {
          border-radius: 50%;
          margin: 0 10px;
          color: $textColor;
          font-size: 28px;
          box-shadow: 0px 10px 44px 0 rgba(17, 70, 96, 0.2);
          &:nth-child(4) {
            background-color:white;
          }
          &:hover {
            color: $hoverColor;
          }
        }

      }
    }

  }

}



.header-wr {
  position: relative;
  height: auto;
  min-height: 105px;
  width: 100%;
  border-bottom: 1px solid $gray-F1;
}


#lang-choiser {
  a {
    text-decoration: none;
  }

  li {
      display: block
  }

  .dropdown {
    position: absolute;
    display: none;
  }
}