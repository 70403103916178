.signin-form {
  background-image: url(../img/wave-content.svg);
  background-position: 0 230px;
  padding-bottom: 100px;
  @media #{$M_XS} {
    padding-bottom: 50px;
  }
  & .form {
    margin-top: 50px;
  }
  & .checkbox-label {
    width: 100%;
    max-width: 50%;
    margin-top: 35px;
    @media #{$M_XS} {
      max-width: 100%;
    }
    & input[type="checkbox"] {
      display: none;
      &:checked {
        & ~ label {
          & i {
            background-color: $yellow-FE;
          }
        }
      }
    }
    & label {
      position: relative;
      width: 100%;
      padding-left: 46px;
      padding-top: 1px;
      font-size: 18px;
      font-family: $font-OpenSans-Regular;
      & i {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        height: 30px;
        width: 30px;
        border: 1px solid $gray-E0;
        @include border-radius(3px);
        background-color: $white;
        cursor: pointer;
      }
    }
  }
  & .pass-restore {
    width: 100%;
    max-width: 50%;
    margin-top: 35px;
    margin-left: auto;
    text-align: right;
    font-family: $font-OpenSans-Regular;
    font-size: 18px;
    color: $blue-38;
    @media #{$M_XS} {
      max-width: 100%;
      margin-top: 55px;
      text-align: center;
    }
    & a {
      font-size: inherit;
      font-family: inherit;
      color: inherit;
    }
  }
}