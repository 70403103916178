.content-secondary {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  padding-bottom: 200px;
  @include linear-gradient(to bottom, $blue-F0, $white);
  @media #{$M_XS} {
    padding-bottom: 100px;
  }
  @media #{$M_X2SS} {
    padding-bottom: 100px;
  }
  & .page-bottom {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 754px;
    background-position: 0 0;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-image: url('../img/wave-section-bottom.svg');
    z-index: 1;
    @media #{$M_XS} {
      height: 350px;
    }
    & .inner-wr {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 170px;
      max-width: 840px;
      @media #{$M_XS} {
        bottom: 55px;
      }
    }
  }
}
.inner-wr {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  z-index: 2;
}
.sub-title-wr {
  width: 100%;
  max-width: 1080px;
  margin: 200px auto 0;
  text-align: center;
  &.secondary {
    margin: 130px auto 0;
    @media #{$M_SMX} {
      margin: 90px auto 0;
    }
  }
  @media #{$M_SMX} {
    padding: 0 40px;
    margin: 50px auto 0;
  }
  & .title {
    font-family: $font-Montserrat-SemiBold;
    font-size: 30px;
    color: $gray-4B;
    @media #{$M_XS} {
      font-size: 28px;
      line-height: 36px;
    }
  }
  & p {
    font-size: 18px;
    line-height: 1.6em;
    margin-top: 19px;
    color: $gray-72;
    @media #{$M_XS} {
      font-size: 17px;
    }
  }
  & .wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 72px 0 46px;
    @media #{$M_XS} {
      flex-flow: column nowrap;
    }
    .half {
      color: #35a4e8;
      &__main {
        font-size: 60px;
        font-family: $font-OpenSans-Light;
        margin-bottom: 26px;
      }
      &__descr {
        font-size: 20px;
      }
      // &:first-of-type {
      //   @media #{$M_XS} {
      //     margin-bottom: 60px
      //   }
      // }
    }
  }

  & .separator {
    background-color: #d3dce0;
    width: 1px;
    margin: 0 165px;
    @media #{$M_SM2} {
      margin: 0 80px;
    }
    @media #{$M_XS} {
      height: 1px;
      width: 50%;
      margin: 60px auto;
    }
  }
}
.discover, .format-bl {
  & .btn-default {
    background-color: white;
    box-shadow: 0px 2px 12px 0 rgba(17, 70, 96, 0.06);
    width: unset;
    padding: 0 32px;
  }
}

.offer-bl {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 80px auto 0;
  z-index: 2;
  @media #{$M_SM2X} {
    padding: 0 15px;
  }
  @media #{$M_XS} {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0;
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    max-width: 326px;
    text-align: center;
    @media #{$M_SMX} {
      max-width: 280px;
    }
    @media #{$M_SM2} {
      max-width: 220px;
    }
    @media #{$M_XS} {
      width: 100%;
      max-width: 480px;
      margin-top: 35px;
      padding: 0 25px;
    }
    &.row-1 {

      & .pic {
        background-image: url(../img/attractive.png);
        @include image-2x('../img/attractive_@2x.png', 30%, 30%);
      }
    }
    &.row-2 {
      & .pic {
        background-image: url(../img/easy-to-use.png);
        @include image-2x('../img/easy-to-use_@2x.png', 30%, 30%);
      }
    }
    &.row-3 {
      & .pic {
        background-image: url(../img/on-time.png);
        @include image-2x('../img/on-time_@2x.png', 30%, 30%);
      }
    }
    & .pic {
      height: 80px;
      width: 80px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      -webkit-box-shadow: 0px 2px 30px 0px rgba(13, 89, 213, 0.2);
      -moz-box-shadow:    0px 2px 30px 0px rgba(13, 89, 213, 0.2);
      box-shadow:         0px 2px 30px 0px rgba(13, 89, 213, 0.2);
      @include border-radius(100%);
      background-color: $white;
      overflow: hidden;
    }
    & .title {
      width: 100%;
      margin-top: 40px;
      line-height: 1.2em;
      font-family: $font-Montserrat-SemiBold;
      font-size: 23px;
      color: $gray-3C;
      @media #{$M_XS} {
        font-size: 20px;
      }
    }
    & .text {
      width: 100%;
      margin-top: 16px;
      line-height: 28px;
      font-size: 18px;
      color: $gray-72;
      @media #{$M_XS} {
        font-size: 17px;
      }
    }
  }
}
.format-bl {
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  margin-top: 81px;
  @media #{$M_SM2} {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @media #{$M_SM2X} {
    max-width: 1024px;
    margin: 81px auto 0;
    padding: 0 30px;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }
  @media #{$M_SMX} {
    max-width: 904px;
    padding: 0;
  }
  @media #{$M_XS} {
    margin-top: 0;
  }
  &__btn-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
  &__item {
    display: flex;
    padding: 25px 30px 15px;
    background-color: $white;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 32px;
    @include border-radius(1em);
    -webkit-box-shadow: 0px 10px 44px 0px rgba(17, 70, 96, 0.14);
    -moz-box-shadow:    0px 10px 44px 0px rgba(17, 70, 96, 0.14);
    box-shadow:         0px 10px 44px 0px rgba(17, 70, 96, 0.14);
    @media #{$M_SM2X} {
      width: 100%;
      margin: 20px 15px 0;
      padding: 36px 20px 40px;
    }
    @media #{$M_SM2} {
      flex-flow: column nowrap;
      max-width: 600px;
      height: auto;
      margin: 20px auto 0;
      padding: 50px;
    }
    @media #{$M_XS} {
      width: 80%;
      max-width: 380px;
      padding: 23px 20px 35px;
    }

    &.row-1 {
      & .pic {
        background-image: url(../img/display.png);
        @include image-2x('../img/display_@2x.png', contain, contain);
      }
    }
    &.row-2 {
      & .pic {
        background-image: url(../img/native.png);
        @include image-2x('../img/native_@2x.png', contain, contain);
      }
    }
    &.row-3 {
      & .pic {
        background-image: url(../img/pop.png);
        @include image-2x('../img/pop_@2x.png', contain, contain);
      }
    }
    &.row-4 {
      & .pic {
        background-image: url(../img/video.png);
        @include image-2x('../img/video_@2x.png', contain, contain);
      }
    }
    & .pic {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
      height: 175px;
      width: 320px;
      flex-shrink: 0;
    }

    & .description {
      border-left: 1px solid #dde6e8;
      padding-left: 80px;
      @media #{$M_SM2} {
        padding-left: 0;
        border-left: none;
        align-self: flex-start;
      }
    }

    & .title {
      text-align: left;
      font-size: 24px;
      margin-bottom: .4em;
      font-family: $font-Montserrat-Bold;
      color: $gray-4B;
      @media #{$M_XS} {
        font-size: 22px;
        text-align: center;
        margin-bottom: 16px
      }
    }
    & .text {
      line-height: 1.6em;
      text-align: left;
      font-size: 16px;
      font-family: $font-OpenSans-SemiBold;
      color: $gray-4B;
      margin-bottom: .6em;
      @media #{$M_XS} {
        font-size: 16px;
        text-align: center;
      }
    }
    & .text-secondary {
      line-height: 1.6em;
      font-size: 16px;
      text-align: left;
      font-family: $font-OpenSans-SemiBold;
      color: $gray-9B;
      @media #{$M_XS} {
        font-size: 16px;
        text-align: center;
      }
    }
  }
}
.form-wr {
  width: 100%;
  background-repeat: no-repeat;
  background-position: 0 50px;
  -webkit-background-size: 100% auto;
  background-size: contain;
  background-image: url(../img/bg_graph_form.png);
  @include image-2x('../img/bg_graph_form.png', contain, contain);
  z-index: 2;
  & .form {
    position: relative;
    height: auto;
    width: 100%;
    max-width: 1080px;
    margin: 222px auto 0;
    padding: 100px 90px 70px;
    background-color: $white;
    @include border-radius(1em);
    -webkit-box-shadow: 0px 4px 30px 0px rgba(14, 26, 48, 0.08);
    -moz-box-shadow:    0px 4px 30px 0px rgba(14, 26, 48, 0.08);
    box-shadow:         0px 4px 30px 0px rgba(14, 26, 48, 0.08);
    @media #{$M_SM2X} {
      max-width: 1024px;
    }
    @media #{$M_SMX} {
      max-width: 904px;
    }
    @media #{$M_SM2} {
      @include border-radius(0);
    }
    @media #{$M_XS} {
      margin: 60px auto 0;
      padding: 55px 30px 60px;
    }
    & .title-wr {
      line-height: 43px;
      font-size: 32px;
      font-family: $font-Montserrat-SemiBold;
      color: $gray-4B;
      @media #{$M_XS} {
        font-size: 20px;
        line-height: 1.4em;
      }
      & .subtitle {
        &.gray {
          color: $gray-C7;
        }
      }
    }
    & .section-label {
      margin-top: 90px;
      width: 100%;
      @media #{$M_XS} {
        margin-top: 33px;
      }
    }
    & .section-title {
      padding-bottom: 17px;
      font-family: $font-Montserrat-Medium;
      font-size: 18px;
      border-bottom: 1px solid $gray-EB;
      color: $gray-B6;
      @media #{$M_XS} {
        font-size: 14px;
      }
      @media #{$M_X3S} {
        letter-spacing: 1px;
      }
    }
    & .input-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 16px;
      & .input-label {
        width: 48.223%;
        @media #{$M_SMX} {
          width: 100%;
        }
      }
      @media #{$M_XS} {
        margin-top: 0;
      }
    }
    & .label {
      width: 100%;
      margin-top: 33px;
      font-family: $font-OpenSans-Regular;
      font-size: 18px;
      color: $black-27;
      &.alert {
        & .form-input {
          border-color: $red-FB;
        }
      }
      @media #{$M_XS} {
        margin-top: 27px;
      }
      & span {
        padding-left: 5px;
        color: $red-F8;
      }
      & .input-title {
        padding-bottom: 14px;
      }
      & input {
        height: 60px;
        line-height: 60px;
      }
      & select {
        height: 60px;
        line-height: 60px;
      }
      & textarea {
        padding: 15px 15px;
        height: 180px;
        resize: none;
      }
    }
    & .textarea-label {
      margin-top: 25px;
    }
    & .form-input {
      width: 100%;
      padding: 0 18px;
      font-size: 22px;
      font-family: $font-OpenSans-Regular;
      border: 1px solid $gray-E0;
      background-color: $white;
      @include border-radius(0.2em);
      outline: none;
      color: $black-17;
      &:focus {
        border-color: $blue-97;
      }
    }
    & .captcha-label {
      display: flex;
      align-items: center;
      margin-top: 50px;
      font-size: 18px;
      font-family: $font-OpenSans-Regular;
      color: $gray-AA;
      @media #{$M_XS} {
        flex-flow: column wrap;
        align-items: flex-start;
        margin-top: 30px;
      }
      & a {
        font-family: inherit;
        font-size: inherit;
        color: $blue-30;
      }
      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 90px;
        width: 330px;
        text-align: center;
        border: 1px solid $gray-E0;
        background-color: $white;
        @include border-radius(4px);
        overflow: hidden;
        @media #{$M_XS} {
          width: 260px;
        }
        & img {
          @include border-radius(4px);
          height: 78px;
        }
      }
      &__text {
        margin-left: 30px;
        @media #{$M_XS} {
          width: 100%;
          margin-top: 15px;
          margin-left: 0;
        }
      }
    }
    & .btn-wr {
      display: flex;
      justify-content: center;
      margin-top: 75px;
      @media #{$M_XS} {
        margin-top: 60px;
      }
      & .form-btn {
        line-height: 65px;
        padding: 0 38px;
        font-family: $font-Montserrat-Medium;
        font-size: 24px;
        background-color: $yellow-FE;
        color: $white;
        &:active {
          background-color: $orange-F9;
        }
      }
    }
  }
}