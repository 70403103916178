@mixin clearfix {
	&:before,
	&:after {
		content: '';
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin visually-hidden {
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip: rect(0, 0, 0, 0);
	position: absolute;
}

@mixin font-smoothing($value: on) {
	@if $value == on {
		font-smoothing: antialiased;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		-ms-font-smoothing: antialiased;
		-o-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	} @else if $value == off {
		font-smoothing: none;
		-webkit-font-smoothing: none;
		-moz-font-smoothing: none;
		-ms-font-smoothing: none;
		-o-font-smoothing: none;
		-moz-osx-font-smoothing: unset;
	} @else {
		font-smoothing: subpixel-antialiased;
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-font-smoothing: subpixel-antialiased;
		-ms-font-smoothing: subpixel-antialiased;
		-o-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

@mixin absolute($top: auto, $right: auto, $bottom: auto, $left: auto) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin fixed($top: auto, $right: auto, $bottom: auto, $left: auto) {
	position: fixed;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
		 (-o-min-device-pixel-ratio: 2.6/2),
		 (-webkit-min-device-pixel-ratio: 1.3),
		 (min-device-pixel-ratio: 1.3),
		 (min-resolution: 1.3dppx) {
	/* on retina, use image that's scaled by 2 */
	background-image: url($image);
	background-size: $width $height;
  }
}

@mixin linear-gradient($direction, $color-start, $color-stops) {
	background: $color-start; /* Old browsers */
	background: -moz-linear-gradient($direction, $color-start 0%, $color-stops 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient($direction, $color-start 0%, $color-stops 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient($direction, $color-start 0%, $color-stops 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-start', endColorstr='$color-stops',GradientType=0 ); /* IE6-9 */
}

@mixin border-radius($radius) {
    -webkit-border-radius:  $radius;
    -moz-border-radius:     $radius;
    -o-border-radius:       $radius;
    border-radius:          $radius;
}

@mixin transform-translateY($value) {
  -moz-transform: translateY($value);
  -ms-transform: translateY($value);
  -webkit-transform: translateY($value);
  -o-transform: translateY($value);
  transform: translateY($value);
}

@mixin transform-rotate($angle) {
  -moz-transform: rotate($angle);
  -ms-transform: rotate($angle);
  -webkit-transform: rotate($angle);
  -o-transform: rotate($angle);
  transform: rotate($angle);
}