/*Core*/
@import 'helpers/vars';
@import 'helpers/mixins';

/*Reset*/
@import 'base/reset';

/*Typography*/
@import 'base/fonts';

/*Scaffold*/
@import 'base-scaffold/scaffold';

/*Sprite-svg*/
@import 'sprite-svg/_sprite-svg';
/*@import 'sprite/sprite';*/

@import "/home/admachine/public_html/assets/css/templates/cases.scss";
@import "/home/admachine/public_html/assets/css/templates/content-secondary.scss";
@import "/home/admachine/public_html/assets/css/templates/footer.scss";
@import "/home/admachine/public_html/assets/css/templates/general.scss";
@import "/home/admachine/public_html/assets/css/templates/header.scss";
@import "/home/admachine/public_html/assets/css/templates/main-content.scss";
@import "/home/admachine/public_html/assets/css/templates/modal.scss";
@import "/home/admachine/public_html/assets/css/templates/monetize-form.scss";
@import "/home/admachine/public_html/assets/css/templates/page-top.scss";
@import "/home/admachine/public_html/assets/css/templates/signin-form.scss";
@import "/home/admachine/public_html/assets/css/templates/signup-form.scss";
@import "/home/admachine/public_html/assets/css/templates/slider.scss";
@import "/home/admachine/public_html/assets/css/templates/thanks-content.scss";
@import "/home/admachine/public_html/assets/css/templates/toast.scss";

@import "/home/admachine/public_html/assets/css/general/general.scss";
