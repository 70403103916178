.modal {
    position: fixed;
    background: rgba(255,255,255,0.8);
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    z-index: 100;
    display: none;
    &__window {
        min-width: 60%;
        max-width: 90%;
        min-height: 300px;
        box-shadow: 0 4px 35px -12px;
        background-color: white;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        padding: 60px;
        justify-content: center;
    }
    &__close {
        position: absolute;
        right: 12px;
        top: 6px;
        font-size: 20px;
        transform:scale(2, 1.5);
        line-height: 1em;
        padding: 8px;
        opacity: .4;
    }

    &__close {
        position: absolute;
        right: 24px;
        top: 16px;
        font-size: 20px;
        transform:scale(2, 1.5);
        line-height: 1em;
        padding: 8px;
        opacity: .4;
        &:hover {
            opacity: .8;
        }
    }

    &__button_container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end
    }

    &__button {
        background-color: #116fff;
        color: white;
        padding: 22px 60px;
        border-radius: 6px;
        font-size: 18px;
        font-family: $font-Montserrat-Medium;
        max-width: 300px;
        &:hover {
            background-color: #0054d5;
        }
    }
}

.main-form {
    &__list {
        margin: 60px 0;
    }
    &__section:first-child .main-form__list {
        padding-top: 48px;
    }

    &__item {
        display: flex;
        align-items: center;
        padding-bottom: 30px;
    }

    &__label {
        display: inline-block;
        width: 237px;
        font-size: 18px;
    }

    &__textarea {
        flex: 1;
        width: 100%;
        height: 180px;
        resize: none;
        padding-top: 15px;
        padding-bottom: 15px;
        padding: 20px;
        background-color: #FFF;
        border-radius: 3px;
        border: 1px solid #e0e0e0;
        font-size: 22px;
    }

    &__input {
        height: 60px;
        flex: 1;
        width: 100%;
        padding: 0 20px 0 20px;
        background-color: #FFF;
        border-radius: 3px;
        border: 1px solid #e0e0e0;
        font-size: 22px;
        min-width: 400px;
    }
}