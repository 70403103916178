.monetize-form {
  background-image: url(../img/wave-content.svg);
  background-position: 0 412px;
  padding-bottom: 100px;
  @media #{$M_XS} {
    padding-bottom: 50px;
  }
  & .form {
    margin-top: 50px;
  }
}