.btn-default {
  @include border-radius(0.4em);
  display: inline-block;
  line-height: 52px;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
}
body {
  &.overflow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}
.form-anchor {
  position: relative;
  display: inline-block;
  height: 0px;
  width: 100%;
}
.form {
  & .form-anchor {
    position: absolute;
    left: 0;
    top: -50px;
  }
}
.page-wr {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 100%;
  min-height: 100%;
}

strong {
  font-weight: 700;
  font-family: $font-Montserrat-Bold;
}

.tns-nav  {
  bottom: -90px;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: center;
  @media #{$M_X2S} {
    display: none;
  }
}

.tns-nav > button {
  margin: 0 10px;
  padding: 10px;
  background-color: #0074fe;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  opacity: .3;
  border: 20px solid white;
}

.tns-nav > button.tns-nav-active {
  opacity: 1;
}