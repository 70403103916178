.main-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 0px;
  padding-bottom: 100px;
  @media #{$M_XS} {
    padding-top: 0px;
    padding-bottom: 50px;
  }
  .pad1:not(.save) {
    padding-top: 200px;
    @media #{$M_XS} {
      padding-top: 70px;
    }
    @media #{$M_X3SS} {
      padding-top: 50px;
    }
  }

  .bot1 {
    padding-bottom: 200px;
    @media #{$M_XS} {
      padding-bottom: 70px;
    }
    @media #{$M_X3SS} {
      padding-bottom: 50px;
    }
  }
  .mar1 {
    margin-top: 200px;
    @media #{$M_XS} {
      margin-top: 70px;
    }
    @media #{$M_X3SS} {
      margin-top: 50px;
    }
  }
  .width-l {
    width: 55.5%;
  }
  .width-r {
    width: 44.5%;
  }
  & .content-item {
    position: relative;
    width: 100%;

    @media #{$M_XS} {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
    &:last-of-type {
      padding-bottom: 0;
      &:after {
        display: none;
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      height: 395px;
      width: 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-image: url('../img/wave-content.svg');
      background-size: cover;
      z-index: -1;
      @media #{$M_SMX} {
        background-position: 0 100%;
        height: 300px;
        background-size: contain;
      }
      @media #{$M_XS} {
        height: 130px;
        background-size: cover;
        background-position: 0 0;
      }
    }
    &.pic-R {
      & .content-item__pic-bl {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        @media #{$M_XS} {
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
          order: 1;
        }
        &:before {
          right: 0;
          bottom: 0;
        }
      }
      & .content-item__text-bl {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        @media #{$M_XS} {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
          width: 100%;
        }
      }
    }
    &__inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      max-width: 1080px;
      margin: 0 auto;

      @media #{$M_SM2X} {
        max-width: 1024px;
        padding-right: 15px;
        padding-left: 15px;
      }
      @media #{$M_SMX} {
        max-width: 904px;
      }
      @media #{$M_XS} {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-right: 0;
        padding-left: 0;
      }
    }
    &.pic-1 {
      & .save {
        padding-top: 150px;
        @media #{$M_XS} {
          padding-top: 70px;
        }
        @media #{$M_X3SS} {
          padding-top: 50px;
        }
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        &-image {
          height: 126px;
          background-repeat: no-repeat;
          @media #{$M_XS} {
            background-position: 50% 50% !important;
          }
        }
        &-time {
          width: 55.5%;
        }
        &-money {
          width: 44.5%;
          @media #{$M_XS} {
            margin-top: 50px;
          }
        }
        .text-bl-list {
          @media #{$M_XS} {
            max-width: 100%;
            text-align: center;
          }
        }
        @media #{$M_XS} {
          text-align: center;
        }
      }
      & .card {
        &-title {
          display: block;
          color: #131314;
          font-family: $font-Montserrat-Bold;
          font-size: 32px;
          padding: 44px 0px 20px 0px;
          @media #{$M_XS} {
            font-size: 20px;
            padding: 24px 0px 0px 0px;
          }
        }

        @media #{$M_XS} {
          min-width: 100%;
          width: 100%;
          margin-bottom: 70px;
        }
        @media #{$M_X3SS} {
          margin-bottom: 50px;
        }
      }
      & .card:last-child {
        @media #{$M_XS} {
          margin-bottom: 0;
        }
      }
      .inner-chess_block {
        @media #{$M_XS} {
          height: auto;
          text-align: center;
        }
        .mobile-title {
          @media #{$M_XS} {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
          }
        }
        .block_text {
          -ms-flex-item-align: center;
          align-self: center;
          @media #{$M_SM2} {
            width: 100%;
          }
          @media #{$M_XS} {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
          }
        }
        .formats {
          padding-right: 90px;
          @media #{$M_SM2} {
            padding-right: 0;
          }
        }
        .block_img {
          height: 370px;
          background-repeat: no-repeat;
          background-size: contain;

          @media #{$M_SM2} {
            width: 100%;
          }
          @media #{$M_XS} {
            margin-bottom: 35px;
            background-position: 50%;
            height: 260px;
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
          }
          @media #{$M_X3S} {
            height: 200px;
          }
        }
      }

      .inner-traffic_sources {
        .traffic_sources_text {
          -ms-flex-item-align: center;
          align-self: center;
          width: 55.5%;
          @media #{$M_SM2} {
            width: 100%;
          }
          @media #{$M_XS} {
            text-align: center;
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
          }
        }
        .traffic_sources_img {
          width: 44.5%;
          margin-bottom: 35px;
          @media #{$M_SM2} {
            width: 100%;
          }

          .traffic_sources_blocks {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
            grid-auto-rows: minmax(100px, auto);
            @media #{$M_X2S} {
              grid-auto-rows: minmax(70px, auto);
            }
            @media #{$M_X3S} {
              grid-auto-rows: minmax(50px, auto);
            }
            .traffic_sources_block {
              background: #fff;
              background-repeat: no-repeat;
              background-position: 50%;
              -webkit-box-shadow: 0px 6px 24px 0px rgba(17, 70, 96, 0.1);
              box-shadow: 0px 6px 24px 0px rgba(17, 70, 96, 0.1);
              @media #{$M_X2S} {
                background-size: 36px;
              }
              @media #{$M_X3S} {
                background-size: 22px;
              }
            }
            .traffic_sources_block:last-child {
              grid-column-start: 1;
            }
          }
        }
      }

      .inner-cases_studios {
        display: block;
      }
    }

    &__inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      max-width: 1080px;
      margin: 0 auto;

      @media #{$M_SM2X} {
        max-width: 1024px;
        padding-right: 15px;
        padding-left: 15px;
      }
      @media #{$M_SMX} {
        max-width: 904px;
      }
      @media #{$M_XS} {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-right: 0;
        padding-left: 0;
      }
    }
    .inner-formats {
      .mobile-title {
        @media #{$M_XS} {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
        }
      }
      .formats {
        width: 55.5%;
        padding-right: 180px;
        @media #{$M_SM2} {
          width: 100%;
        }
        @media #{$M_XS} {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
        }
        @media #{$M_SMX} {
          padding-right: 0;
        }
      }
      .formats-img {
        width: 44.5%;
        height: 361px;
        @media #{$M_SM2} {
          width: 100%;
        }
        @media #{$M_XS} {
          height: 260px;
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
          order: 1;
        }
      }
    }
    .inner-platforms {
      .mobile-title {
        @media #{$M_XS} {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
        }
      }
      .formats-img {
        height: 360px;
        @media #{$M_SM2} {
          width: 100%;
        }
        @media #{$M_XS} {
          height: 260px;
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
          order: 1;
        }
      }
    }

    .inner-top_geos {
      display: block;
      .top_geos {
        width: 100%;
        padding: 0;
      }
      .top_geos_list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        padding-top: 40px;
        height: 360px;
        @media #{$M_SM2X} {
          height: 470px;
        }
        @media #{$M_X2S} {
          height: unset;
        }
        &-block {
          padding-bottom: 30px;
          .block-info {
            display: table;
            width: 300px;
            &-number {
              text-align: right;
            }
            &-country {
              padding-left: 14px;
            }
            &-country,
            &-number {
              display: table-cell;
              vertical-align: middle;
              color: #131314;
              font-size: 18px;
              font-family: $font-Montserrat-Medium;
              @media #{$M_XS} {
                font-size: 14px;
              }
            }
            @media #{$M_X2S} {
              width: 100%;
            }
          }
          .block-img {
            width: 20px;
            height: 20px;
            display: table-cell;
            background-repeat: no-repeat;
          }
        }
        &-block:last-child {
          padding-bottom: 0;
        }
      }
    }
    .inner-monetize {
      .monetize {
        width: 100%;
        padding: 0;
        &-block {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          background: #fff;
          -webkit-box-shadow: 0px 6px 24px 0px rgba(17, 70, 96, 0.1);
          box-shadow: 0px 6px 24px 0px rgba(17, 70, 96, 0.1);
          padding: 64px 30px 64px 0px;
          margin-top: 40px;
          @media #{$M_XS} {
            padding: 44px 30px;
            text-align: center;
          }
          &-img {
            width: 50%;
            height: 300px;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            @media #{$M_XS} {
              width: 100%;
              height: 200px;
            }
            @media #{$M_X4S} {
              height: 150px;
            }
          }
          &-info {
            width: 50%;
            .content-item__item {
              font-size: 18px;
              color: #131314;
              padding-bottom: 24px;
              line-height: 26px;
              @media #{$M_XS} {
                font-size: 14px;
              }
              @media #{$M_X3SS} {
                font-size: 12px;
                text-align: left;
              }
            }
            .content-item__item:last-of-type {
              padding-bottom: 0;
            }
            @media #{$M_XS} {
              width: 100%;
              padding-top: 40px;
            }
          }
        }
      }
    }

    .inner-people_talk {
      .people_talk {
        width: 100%;
        padding: 0;
        &-blocks {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 40px;
          grid-auto-rows: minmax(100px, auto);
          margin-top: 40px;
          @media #{$M_XS} {
            grid-template-columns: repeat(3, 1fr);
          }
          @media #{$M_X2S} {
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: minmax(80px, auto);
          }
          @media #{$M_X3S} {
            grid-gap: 20px;
          }
        }
        &-block {
          background: #fff;
          background-repeat: no-repeat;
          background-position: 50%;
          -webkit-box-shadow: 0px 6px 24px 0px rgba(17, 70, 96, 0.1);
          box-shadow: 0px 6px 24px 0px rgba(17, 70, 96, 0.1);
          @media #{$M_X2S} {
            background-size: 70px;
          }
        }
        &-block:nth-child(5) {
          grid-column-start: 2;
          @media #{$M_X2S} {
            grid-column-start: 1;
          }
        }
        &-title {
          text-align: center;
        }
      }
      .mobile-title {
        text-align: center;
      }
    }
    .inner-get_rewards {
      background: #fff;
      -webkit-box-shadow: 0px 6px 24px 0px rgba(17, 70, 96, 0.1);
      box-shadow: 0px 6px 24px 0px rgba(17, 70, 96, 0.1);
      padding: 44px 30px 44px 0;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      @media #{$M_XS} {
        padding: 20px 10px 20px 0;
      }
      .get_rewards-img {
        height: 274px;
        background-size: contain;
        align-self: center;
        background-repeat: no-repeat;
        width: 40%;
        background-position: -20px;
        @media #{$M_XS} {
          height: 230px;
        }
        @media #{$M_X3S} {
          background-size: cover;
          background-position: -120px;
        }
        @media #{$M_X4S} {
          background-position: -135px;
        }
      }
      .get_rewards-info {
        width: 60%;
        -ms-flex-item-align: center;
        align-self: center;
        .get_rewards-title {
          padding-bottom: 50px;
          line-height: inherit;
        }
        .mobile-title {
          @media #{$M_XS} {
            padding-bottom: 30px;
          }
        }
        .get_rewards-description {
          color: #131314;
          line-height: 20px;
          .get_rewards-href {
            text-decoration: none;
            opacity: 1;
            color: #116fff;
            &:hover {
              text-decoration: none;
              opacity: 0.8;
            }
            &:active {
              opacity: 0.9;
            }
          }
        }
      }
      @media #{$M_XS} {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
    }
    .inner-dev_up {
      .dev_up-info {
        width: 100%;
        &-blocks {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: end;
          -ms-flex-align: end;
          align-items: flex-end;
          margin-top: 50px;
          @media #{$M_X2SS} {
            margin-top: 30px;
          }
          .content-btn {
            padding: 0 40px;
            @media #{$M_SMXX2SS} {
              margin-top: 0;
            }
          }
          .content-btn-blue {
            @media #{$M_SMXX2SS} {
              margin-top: 0;
            }
            @media #{$M_X2SS} {
              margin-top: 30px;
            }
          }
        }
        &-block {
          width: 326px;
          text-align: center;
          border: 1px solid #bbd8ef;
          border-radius: 5px;
          background: $white;
          padding: 40px 30px 50px 30px;

          &-img {
            width: 100%;
            height: 53px;
            background-repeat: no-repeat;
            background-position: 50%;
            @media #{$M_SMXX2SS} {
              width: 20%;
              background-position: 0;
              background-size: contain;
            }
          }

          &-img:first-child {
            @media #{$M_SMXX2SS} {
              height: 32px;
            }
          }
          &-img:last-child {
            @media #{$M_SMXX2SS} {
              height: 53px;
            }
          }
          &-name {
            padding-top: 54px;
            @media #{$M_SMXX2SS} {
              padding-top: 0;
              text-align: left;
              width: 55%;
            }

            @media #{$M_X2SS} {
              padding-top: 28px;
              font-size: 14px;
            }
          }
          &-text {
            color: #116fff;
            font-size: 20px;
            font-family: $font-Montserrat-SemiBold;
            @media #{$M_X2SS} {
              font-size: 14px;
            }
          }
          &-price {
            font-size: 26px;
            color: #131314;
            line-height: 52px;
            white-space: pre-line;
            padding-top: 16px;
            @media #{$M_X2SS} {
              font-size: 18px;
              line-height: 26px;
              padding-top: 6px;
            }
          }
          &-list {
            padding-top: 18px;
            .text-bl-list__item {
              font-size: 14px;
            }
            @media #{$M_XS} {
              padding: 0;
            }
          }
          &-premium {
            padding-top: 77px;
            box-shadow: 0px 6px 24px 0px rgba(17, 70, 96, 0.1);
            @media #{$M_X2SS} {
              padding-top: 27px;
            }
          }
          &-white {
            padding-top: 115px;
            @media #{$M_X2SS} {
              padding-top: 27px;
            }
            .dev_up-info-block-img {
              @media #{$M_SMXX2SS} {
                height: 53px;
              }
            }
          }
          @media #{$M_SMXX2SS} {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            padding: 50px;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
          }
          @media #{$M_X2SS} {
            width: 100%;
            padding: 27px 30px;
          }
        }
        &-block:first-child {
          @media #{$M_SMX} {
            margin-bottom: 20px;
          }
        }
        &-block:nth-child(2) {
          @media #{$M_SMX} {
            margin-bottom: 20px;
          }
        }
        .dev_up-title {
          text-align: center;
        }
        .dev_up-text {
          color: #116fff;
          font-size: 32px;
          text-align: center;
          font-family: $font-Montserrat-Bold;
          padding-top: 10px;
          font-weight: normal;
          @media #{$M_XS} {
            font-size: 20px;
          }
        }
      }
      .mobile-title {
        text-align: center;
      }
    }
    .inner-footer_info {
      .info-href {
        width: 28%;
        .info_link {
          font-size: 18px;
          display: inline-block;
          line-height: 1.2;
          margin-bottom: 44px;
          &:hover:not(.active) {
            text-decoration: underline;
            color: #116fff;
          }
          &:active {
            text-decoration: underline;
            color: #116fff;
          }
          &.active {
            color: #116fff;
          }
        }
      }
      .info-blocks {
        width: 72%;
        .info-block {
          ol {
            -webkit-padding-start: 40px;
            counter-reset: item;
          }

          ol li {
            display: block;
            color: #000;
            font-size: 16px;
            font-family: inherit;
            font-weight: 500;
            margin: 1em 0;
            line-height: 1.5;
          }

          p,
          ol li ol li {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.6em;
          }

          li:before {
            content: counters(item, '.') '. ';
            counter-increment: item;
          }
        }
        .info-title {
          font-size: 30px;
          line-height: 1;
          padding-bottom: 26px;
        }
      }
    }
    &__pic-bl {
      position: relative;
      left: 0;
      bottom: 0;
      width: 55.5%;
      @media #{$M_XS} {
        height: 260px;
        width: 100%;
        margin-bottom: 35px;
      }
      @media #{$M_X3S} {
        height: 210px;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        @media #{$M_XS} {
          height: 100%;
          width: 100%;
          background-position: 50% 50% !important;
        }
      }
    }
    &__text-title {
      width: 100%;
      max-width: 1080px;
      margin: 0 auto;
      padding: 0 0 74px 0;
    }
    & .title {
      display: block;
      color: #131314;
      font-family: $font-Montserrat-Bold;
      font-size: 54px;
      font-weight: normal;
      line-height: 69px;
      @media #{$M_XS} {
        display: none;
        font-size: 24px;
        line-height: 37px;
      }
      @media #{$M_X3SS} {
        line-height: 30px;
      }
      &.mobile-title {
        display: none;
        width: 100%;
        @media #{$M_XS} {
          display: block;
        }
      }
    }
    &__text-bl {
      font-size: 18px;
      color: #131314;
      @media #{$M_XS} {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        font-size: 17px;
        padding-right: 0;
      }

      & p {
        margin-top: 18px;
        line-height: 28px;
        font-family: $font-base;
        @media #{$M_XS} {
          width: 100%;
          max-width: 500px;
          font-size: 17px;
          line-height: 1.6em;
        }
      }
    }
    & .text-bl-list {
      margin-top: 24px;
      @media #{$M_XS} {
        width: 100%;
      }
      &__item {
        margin-bottom: 32px;
        color: #6b747d;
        font-size: 20px;
        line-height: 30px;
        @media #{$M_XS} {
          font-size: 16px;
          margin-bottom: 26px;
        }
        @media #{$M_X3SS} {
          font-size: 14px;
        }
      }
      &__item:last-child {
        margin-bottom: 0;
        line-height: 30px;
      }
    }
  }
}
.content-btn {
  padding: 0 40px;
  display: inline-block;
  margin-top: 54px;
  min-width: 220px;
  text-align: center;
  line-height: 52px;
  text-decoration: none;
  font-size: 20px;
  border-radius: 5px;
  font-family: $font-Montserrat-SemiBold;
  background: -webkit-gradient(linear, left bottom, left top, from(#ffc12a), to(#ff9711)) no-repeat;
  background: linear-gradient(0deg, #ffc12a, #ff9711) no-repeat;
  color: $white;
  -webkit-box-shadow: 0px 6px 0px 0px rgba(232, 156, 0, 1);
  box-shadow: 0px 6px 0px 0px rgba(232, 156, 0, 1);
  &:hover {
    color: $white;
    text-decoration: none;
    opacity: 0.8;
  }
  &:active {
    opacity: 1;
  }

  @media #{$M_XS} {
    margin: 30px auto 0;
  }
  @media #{$M_X3SS} {
    width: 100%;
    min-width: fit-content;
    font-size: 20px;
  }
}
.content-btn-default {
  width: 100%;
}
.content-btn-blue {
  display: inline-block;
  min-width: 220px;
  text-align: center;
  margin-top: 54px;
  height: 52px;
  opacity: 1;
  color: #116fff;
  border: 2px solid #116fff;
  border-radius: 6px;
  font-size: 20px;
  line-height: 48px;
  font-family: $font-Montserrat-SemiBold;
  &:hover {
    background-color: #116fff;
    opacity: 0.8;
    color: #fff;
    text-decoration: none;
  }
  &:active {
    background-color: #116fff;
    opacity: 1;
    color: #fff;
    text-decoration: none;
  }
  @media #{$M_X2SS} {
    min-width: fit-content;
  }
}
