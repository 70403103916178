.page-top {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background: #0094FF;
  min-height: 100vh;
  @media #{$M_SM2} {
    padding-top: 105px;
    padding-bottom: 55px;
  }
  @media #{$M_XS} {
    min-height: 645px;
  }
  @media #{$M_X4S} {
    min-height: 600px;
  }
  &__overlay{
    background-image: url(../img/page_top_bag.png);
    width: 100%;
    height: 100%;
    top: -0px;
    background-repeat: no-repeat;
    position: absolute;
    background-position: 110%;
    background-size: contain;
    opacity: 0.1;
  }
  &__inner {
    margin-top: 104px; // header height
    padding: 40px 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    max-width: 1080px;
    z-index: 2;
    @media #{$M_XLG2} {
      max-width: 1024px;
      padding-right: 30px;
      padding-left: 30px;
    }
    @media #{$M_SMX} {
      max-width: 100%;
    }
    @media #{$M_SM2} {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          margin-top: 0;
    }
    .video_introduction-mobile{
      @media #{$M_X4S} {
        display: block!important;
        text-decoration: none;
        font-size: 13px;
        opacity: 1;
        color: $white;
        height: 100%;
        width: 100%;
        padding-top: 15px;
        text-align: center;
        &:hover{
          opacity: 0.8;
        }

      }
    }
  }
  &__text {
    width: 100%;
    max-width: 600px;
    padding-right: 2em;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    @media #{$M_SM2} {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      width: 100%;
      max-width: 100%;
      padding-right: 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    & .title {
      line-height: 69px;
      font-size: 64px;
      font-family: $font-Montserrat-Bold;
      color: #fff;
      font-weight: normal;
      margin-bottom: 74px;
      @media #{$M_SM2} {
        font-size: 35px;
        text-align: center;
        line-height: 1.3em;
        margin-bottom: 40px;
      }
    }
    .tagline {
      color: #fff;
      font-family:$font-Montserrat-Bold;
      font-size: 22px;
      hr{
        border: 1px solid #fff;
        opacity: 0.2;
        width: 100%;
        float: left;

        @media #{$M_X3S} {
          display: none;
        }
      }
      @media #{$M_SM2} {
        text-align: center;
      }
      @media #{$M_X3S} {
        font-size: 16px;
      }
    }
    & .btn-default {
      display: inline-block;
      text-align: center;
      height: 80px;
      width: auto;
      padding: 0 3em;
      margin-top: 74px;
      line-height: 80px;
      text-decoration: none;
      font-size: 24px;
      border-radius: 5px;
      font-family: $font-Montserrat-SemiBold;
      background: -webkit-gradient(linear, left bottom, left top, from(#FFC12A), to(#FF9711)) no-repeat;
      background: linear-gradient(0deg, #FFC12A, #FF9711) no-repeat;
      color: $white;
      -webkit-box-shadow: 0px 6px 0px 0px #e89c00, 0px 10px 20px 0px rgba(26, 81, 199, 0.5);
      box-shadow:         0px 6px 0px 0px #e89c00, 0px 10px 20px 0px rgba(26, 81, 199, 0.5);
      &:active {
        opacity: 1;
      }
      @media #{$M_SM2} {
        margin-top: 40px;
      }
      @media #{$M_SM3} {
        font-size: 22px;
      }
      @media #{$M_X3SS} {
        font-size: 20px;
        width: 100%;
        text-align: center;
        padding: 0;
      }
    }
  }
  &__video {
    height: 320px;
    width: 480px;
    min-width: 480px;
    @include border-radius(5px);
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-image: url(../img/video-bg.png);
    background-size: contain;
    background-color: #fafdff;
    overflow: hidden;
    cursor: pointer;
    -webkit-box-shadow: 0px 10px 44px 0px rgba(17, 70, 96, 0.14);
    box-shadow:         0px 10px 44px 0px rgba(17, 70, 96, 0.14);
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    @media #{$M_SM2} {
      margin-top: 40px;
    }
    @media #{$M_X3S} {
      height: 176px;
      width: 260px;
      min-width: 260px;
    }
    @media #{$M_X4S} {
      background-image: url(../img/play.png);
      height: 64px;
      width: 64px;
      border-radius: 50%;
      min-width: 64px;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    & span {
      display: inline;
      & a {
        font-family: $font-Montserrat-Medium;
        text-align: center;
        padding-top: 44%;
        text-decoration: none;
        opacity: 1;
        color: #116FFF;
        display: block;
        height: 100%;
        &:hover{
          opacity: 0.8;
        }
        @media #{$M_X4S} {
          display: none!important;
        }
      }
    }

    &:hover {
      -webkit-box-shadow: 0px 20px 64px 0px rgba(17, 70, 96, 0.14);
              box-shadow: 0px 20px 64px 0px rgba(17, 70, 96, 0.14);
      opacity: .8;
      -webkit-transform: translate(0, -5px);
              transform: translate(0, -5px)
    }
  }
}