
.toast-container {
    position: fixed;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    bottom: 0;
    width: 100%;
}

.toast {
    min-width: 300px;
    background-color: white;
    padding: 22px 26px;
    border-radius: 4px;
    box-shadow: 0px 3px 14px -5px rgba(0, 0, 0, 0.45);
    // border-top: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 8px;
    transform: translateY(120%);
    position: absolute;
    bottom: 0;
    transition: 0.5s cubic-bezier(.53,.01,.79,.9) transform;

    &.success {
        border-top: 6px solid #7fe67f;
    }
    &.error {
        border-top: 6px solid red;
    }
    &.show {
        transform: translateY(0%);
        transition: 0.5s cubic-bezier(.24,.75,.03,1.41) transform;
    }

    a {
        color: #38aff8;
        border-bottom: 1px solid #38aff8;
    }

    a:hover {
        text-decoration: none;
        border-bottom: 2px solid #38aff8;
    }
}
