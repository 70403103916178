.inner-cases_studios {
  display: block;
  hr{
    border: 4px solid rgba(0, 148, 255, 0.2);
    border-radius: 5px;
    margin-top: 60px;
    @media #{$M_SM2} {
      margin-top: 40px;
    }
  }
  &-line{
    border: 12px solid rgb(255, 255, 255);
    border-radius: 16px;
    position: relative;
    width: 45%;
    top: -25px;
    box-shadow: 0px 0px 20px 0 rgba(17,70,96,0.1);
    transition: .3s ease all;
  }
  .tns-outer{
    top: -14px;
    position: relative;
  }
  .cases-grid_studios {

    &__cell {
      border-radius: 5px;
      height: 300px;
      width: 90%;
      box-shadow: 0px 4px 30px 0px rgba(14, 26, 48, 0.08);
      background: white;
      overflow: hidden;
      & > a {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-decoration: none;
        line-height: 20px;
      }
      &:hover {
        .cases-grid_studios__background {
          transform: scale(1.05);
        }
      }
    }
    &__background {
      -webkit-transition: -webkit-transform 0.5s ease;
      transition: -webkit-transform 0.5s ease;
      transition: transform 0.5s ease;
      transition: transform 0.5s ease, -webkit-transform 0.5s ease;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-position: center;
    }
  }
  .cases-grid_studios-info {
    margin-top: 20px;
    & a {
      font-size: 20px;
      color: #131314;
      font-family: $font-Montserrat-Medium;
      &:hover {
        color: #bbd8ef;
        text-decoration: none;
      }
      @media #{$M_SM2} {
        font-size: 16px;
      }
      @media #{$M_X3SS} {
        font-size: 14px;
      }
    }
    @media #{$M_SM2} {
      padding-top: 0;
    }
  }
  .cases_studios-title {
    color: #d1ecff;
    font-size: 128px;
    font-family: $font-Montserrat-Bold;
    line-height: 100px;
    @media #{$M_XSM} {
      font-size: 90px;
      line-height: 70px;
    }
    @media #{$M_X3SS} {
      font-size: 40px;
      line-height: 40px;
    }
  }
}
