/*Colors*/
$black: #000;
$black-17: #171515;
$black-27: #27272d;
$white: #FFF;
$gray-AA: #aaaaaf;
$gray-B6: #b6b6ba;
$gray-CD: #cdd9e3;
$gray-C7: #c7ccd4;
$gray-EB: #ebebeb;
$gray-E0: #e0e0e0;
$gray-F1: #f1f2f3;
$gray-3C: #3c4859;
$gray-4B: #4b4e56;
$gray-72: #727d8d;
$gray-99: #999da2;
$gray-9B: #9ba5b0;
$blue-CE: #ceedfd;
$blue-DB: #dbf3ff;
$blue-D0: #d0eefd;
$blue-D3: #d3e4ed;
$blue-F0: #f0f6fa;
$blue-F5: #f5fbff;
$blue-F5F: #f5f8fe;
$blue-28: #28aff1;
$blue-30: #30aded;
$blue-38: #38aff8;
$blue-97: #97d6f6;
$yellow-FF: #ffc12a;
$yellow-FE: #fec03e;
$orange-F9: #f9ba22;
$red-F8: #f8123d;
$red-FB: #fb888d;

/*Links*/
$link: $black;
$link-hover: $black;

/*Typography*/
$font-base: Helvetica, Arial, sans-serif;
$font: 'Montserrat-Regular', $font-base;
$font-Montserrat-Bold: 'Montserrat-Bold', $font-base;
$font-Montserrat-Medium: 'Montserrat-Medium', $font-base;
$font-Montserrat-Regular: 'Montserrat-Regular', $font-base;
$font-Montserrat-SemiBold: 'Montserrat-SemiBold', $font-base;

$font-OpenSans-Bold: 'OpenSans-Bold', $font-base;
$font-OpenSans-Regular: 'OpenSans-Regular', $font-base;
$font-OpenSans-Light: 'OpenSans-Light', $font-base;
$font-OpenSans-SemiBoldItalic: 'OpenSans-SemiBoldItalic', $font-base;
$font-OpenSans-SemiBold: 'OpenSans-SemiBold', $font-base;


$font-size-root: 10px;
$font-size: 1.4rem;

$line-height:20px;

$text: $black;

$light: 300;
$normal: 400;
$semibold: 600;
$bold: 700;

/*Paths*/
$font-img-path: '../images';

/*Media-queries*/
$M_X4S: 'screen and (max-width: 320px)'; // (srv)
$M_X3S: 'screen and (max-width: 479px)'; // (srv)
$M_X2S: 'screen and (max-width: 711px)'; // (srv)
$M_XS: 'screen and (max-width: 768px)'; // phones
$M_XSM: 'screen and (max-width: 869px)';
$M_SM: 'screen and (max-width: 991px)'; // tablets
$M_SMX: 'screen and (max-width: 1023px)';
$M_SM2X: 'screen and (max-width: 1099px)';
$M_SM3X: 'screen and (max-width: 1149px)';
$M_MD: 'screen and (max-width: 1199px)'; // medium desktop
$M_LG: 'screen and (min-width: 1200px)'; // large desktop
$M_XLG: 'screen and (max-width: 1399px)'; // extra-large desktop

$M_X3SS: 'screen and (max-width: 391px)';
$M_X2SS: 'screen and (max-width: 510px)';
$M_SMXX2SS: 'screen and (max-width: 1023px) and (min-width: 510px)'; // combine
$M_SM2: 'screen and (max-width: 904px)';
$M_SM3: 'screen and (max-width: 930px)';
$M_XLG2: 'screen and (max-width: 1320px)';
$XX_LRG: 'screen and (min-width: 1900px)';