.footer {
  height: auto;
  width: 100%;
  background-color: $white;
  padding: 52px 0 46px;
  border-top: 1px solid $gray-F1;
  @media #{$M_X4S} {
    padding: 0 0 50px 0;
    border: none;
  }
  &__inner-wr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    @media #{$M_SM2X} {
      max-width: 1024px;
    }
    @media #{$M_SMX} {
      max-width: 904px;
    }
    @media #{$M_SM2} {
      flex-wrap: wrap;
    }
    @media #{$M_XS} {
    }
  }

  & .footer-nav {
    display: flex;
    @media #{$M_SM2} {
      width: 100%;
      justify-content: space-around;
    }
    @media #{$M_XS} {
      flex-wrap: wrap;
      justify-content: center;
    }
    @media #{$M_X4S} {
      display: none;
    }
    &__item {
      margin-right: 65px;
      font-family: $font-Montserrat-Medium;
      font-size: 16px;
      color: $blue-38;
      @media #{$M_SM2X} {
        margin-right: 35px;
      }
      @media #{$M_XS} {
        width: 100%;
        margin-right: 0;
        text-align: center;
        margin-bottom: 17px;
      }
      &:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
      }
      & a {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
      }
    }
  }

  & .footer-social {
    display: flex;
    @media #{$M_SM2} {
      width: 100%;
      margin-top: 45px;
      justify-content: center;
    }
    @media #{$M_X4S} {
      margin-top: 0;
    }
    &__item {
      display: block;
      height: 22px;
      width: 22px;
      margin-left: 32px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      &:hover {
        opacity: 0.8;
      }
      &.youtube {
        width: 28px;
        background-image: url(../img/social/youtube.svg);
      }
      &.linkedin {
        background-image: url(../img/social/linkedin.svg);
      }
      &.facebook {
        background-image: url(../img/social/facebook.svg);
      }
      &.twitter {
        background-image: url(../img/social/twitter.svg);
      }
      @media #{$M_SM2} {
        margin: 0 12px;
      }
    }
  }



}